var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-margin relative pb-2 _question-panel" },
    [
      _c(
        "div",
        { staticClass: "__difficulty-text" },
        [
          _c("difficulty-indicator", {
            attrs: { difficulty: _vm._.get(_vm.item, "data.difficulty", "") },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex mb-6" }, [
        _c(
          "div",
          {
            staticClass: "bg-grid-color-secondary mr-2",
            staticStyle: { width: "100%" },
          },
          [
            _c("ComplexEditor", {
              attrs: {
                questionnaire_id: _vm.parent_id,
                editor_data: _vm.editorData,
                placeholder: _vm.$t("digite-a-questao-aqui") + "...",
              },
              on: {
                "update:editor_data": function ($event) {
                  _vm.editorData = $event
                },
                blur: _vm.onBlur,
                "data-changed": _vm.onDataChange,
                destroy: _vm.onDestroy,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "options", staticStyle: { "padding-bottom": "20px" } },
        [
          _vm.inlineOptions
            ? _c(
                "div",
                _vm._l(_vm.options, function (option, index) {
                  return _c(
                    "vs-row",
                    { key: option },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "left",
                            "vs-w": "1",
                          },
                        },
                        [
                          _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: _vm.$t("marcar-opcao-correta"),
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{\n              content: $t('marcar-opcao-correta'),\n              delay: { show: 1000, hide: 100 }\n            }",
                                modifiers: { top: true },
                              },
                            ],
                            class: option.selected
                              ? "footer-button checked-button"
                              : "footer-button",
                            attrs: {
                              type: "filled",
                              icon: option.selected
                                ? "check_box"
                                : "check_box_outline_blank",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.itemSelected(option)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "left",
                            "vs-align": "center",
                            "vs-w": "10",
                          },
                        },
                        [
                          _c("TitleComponent", {
                            staticClass: "ml-2 mt-2",
                            attrs: {
                              placeholder: option.label,
                              tag: "h5",
                              item: option,
                              "update-title": false,
                              type: "option",
                            },
                            on: {
                              "title-TITLE_UPDATED": _vm.updateOptionTitle,
                              "title-ENTER_PRESSED": function ($event) {
                                return _vm.addNew(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "left",
                            "vs-align": "center",
                            "vs-w": "1",
                          },
                        },
                        [
                          !("complex" in option && option.complex)
                            ? _c("vs-button", {
                                staticClass: "footer-button",
                                attrs: { type: "filled", icon: "insert_photo" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showImageUploadModal(option)
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.right",
                                value: {
                                  content: "Excluir",
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{\n              content: 'Excluir',\n              delay: { show: 1000, hide: 100 }\n            }",
                                modifiers: { right: true },
                              },
                            ],
                            staticClass: "footer-button",
                            attrs: { type: "filled", icon: "clear" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.deleteOption(option)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                _vm._l(_vm.chunkedOptions, function (opts) {
                  return _c(
                    "div",
                    { key: opts, class: _vm.optionsGridClass },
                    _vm._l(opts, function (option, index) {
                      return _c("vs-row", { key: option }, [
                        option.label !== "new_option"
                          ? _c(
                              "div",
                              { staticClass: "w-full" },
                              [
                                _c(
                                  "vs-col",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "center",
                                      "vs-align": "center",
                                      "vs-w": "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          "min-height": "100px",
                                          "max-width": "50%",
                                          "max-height": "50%",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImageUploadModal(
                                              option
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          key: option.key,
                                          attrs: {
                                            src: !_vm.isEmpty(option.image)
                                              ? option.image.src
                                              : require("@/assets/images/picture-placeholder.png"),
                                            width: "100",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-col",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "center",
                                      "vs-align": "center",
                                      "vs-w": "12",
                                    },
                                  },
                                  [
                                    _c("vs-button", {
                                      class: option.selected
                                        ? "footer-button checked-button"
                                        : "footer-button",
                                      attrs: {
                                        type: "filled",
                                        icon: option.selected
                                          ? "check_box"
                                          : "check_box_outline_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.itemSelected(option)
                                        },
                                      },
                                    }),
                                    _c("vs-button", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.right",
                                          value: {
                                            content: "Excluir",
                                            delay: { show: 1000, hide: 100 },
                                          },
                                          expression:
                                            "{\n                  content: 'Excluir',\n                  delay: { show: 1000, hide: 100 }\n                }",
                                          modifiers: { right: true },
                                        },
                                      ],
                                      staticClass: "footer-button",
                                      staticStyle: {
                                        padding: "8px",
                                        "marging-top": "-10px",
                                      },
                                      attrs: { type: "filled", icon: "clear" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.deleteOption(option)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  {
                                    staticClass: "option_editor",
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "center",
                                      "vs-align": "center",
                                      "vs-w": "12",
                                    },
                                  },
                                  [
                                    _c("TitleComponent", {
                                      attrs: {
                                        placeholder: option.label,
                                        tag: "h5",
                                        item: option,
                                        "update-title": false,
                                        type: "option",
                                      },
                                      on: {
                                        "title-TITLE_UPDATED":
                                          _vm.updateOptionTitle,
                                        "title-ENTER_PRESSED": function (
                                          $event
                                        ) {
                                          return _vm.addNew(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "flex w-full" },
                              [
                                _c(
                                  "vs-col",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "center",
                                      "vs-align": "center",
                                      "vs-w": "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "footer-button",
                                        staticStyle: {
                                          padding: "8px",
                                          "marging-top": "-10px",
                                        },
                                        attrs: { type: "filled", icon: "add" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addNew()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("adicionar-opcao"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ])
                    }),
                    1
                  )
                }),
                0
              ),
          _vm.inlineOptions
            ? _c(
                "div",
                { staticClass: "flex mt-4" },
                [
                  _c(
                    "vs-list",
                    [
                      _c("vs-list-item", [
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "footer-button",
                                staticStyle: {
                                  padding: "8px",
                                  "marging-top": "-10px",
                                },
                                attrs: { type: "filled", icon: "add" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addNew()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("adicionar-opcao")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.questionnaireMode
        ? _c("Footer", {
            attrs: {
              showInvalidateButton: _vm.showInvalidateButton,
              item: _vm.item,
              cheetEnabled: _vm.cheetEnabled,
            },
          })
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: { title: _vm.$t("imagem"), active: _vm.showUploadPopup },
          on: {
            "update:active": function ($event) {
              _vm.showUploadPopup = $event
            },
          },
        },
        [
          _c(
            "vs-tabs",
            {
              attrs: { color: _vm.colorx },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c("vs-tab", { attrs: { label: _vm.$t("computador") } }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap items-center" },
                  [
                    _c(
                      "vue-dropzone",
                      {
                        ref: "fileUploadZone",
                        staticStyle: { width: "100%", "margin-bottom": "10px" },
                        attrs: {
                          id: "upload",
                          "use-font-awesome": true,
                          options: _vm.dropzoneOptions,
                          "use-custom-dropzone-options": true,
                          max_number_of_files: 1,
                          useCustomSlot: true,
                        },
                        on: {
                          "vdropzone-success": _vm.success,
                          "vdropzone-sending": _vm.sending,
                          "vdropzone-error": _vm.error,
                          "vdropzone-file-added": _vm.added,
                          "vdropzone-queue-complete": _vm.complete,
                        },
                      },
                      [
                        _c("div", { staticClass: "dropzone-custom-content" }, [
                          _c("h3", { staticClass: "dropzone-custom-title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("arraste-e-solte-para-enviar-a-imagem")
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "ou-selecione-uma-imagem-do-seu-computador"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "vs-tab",
                { attrs: { label: "$t('google')" } },
                [
                  _c("GoogleSearch", {
                    attrs: { option_id: _vm.selectedOptionId },
                    on: { onImageSelected: _vm.googleImageSelected },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }